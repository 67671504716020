import React from "react";
import TermFilter from "./termFilter";
import VenueFilter from "./venueFilter";
import CourseFilter from "./courseFilter";
import DayFilter from "./dayFilter";

const ClassFilter = (props) => {

    return (
        <div className="filter-container">
            <div className="mobile-to-bar">
                <div className="heading">Filter</div>
                <div className="close" onClick={props.closeMobilePopup}>
                    <img src="/assets/images/cross.svg" alt="Close"/>
                </div>
            </div>
            <div className="mobile-scroll-box">
                <TermFilter OnSearch={props.OnSearch} />
                {/*<VenueFilter OnSearch={props.OnSearch}/>*/}
                <CourseFilter OnSearch={props.OnSearch}/>
                <DayFilter OnSearch={props.OnSearch}/>
            </div>
        </div>
    )

}

export default ClassFilter;
