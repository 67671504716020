import dateFormat from 'dateformat';

class helperFunctions {

    static formatForDateInput(date, options) {
        options = options ?? {};
        options.year = options.year ?? "numeric";
        options.month = options.month ?? "2-digit";
        options.day = options.day ?? "2-digit";

        var year = date.toLocaleString("default", { year: options.year });
        var month = date.toLocaleString("default", { month: options.month });
        var day = date.toLocaleString("default", { day: options.day });

        var formattedDate = year + "-" + month + "-" + day;

        return formattedDate;
    }

    static formatDateForDisplay(date, options) {
        options = options ?? {};
        if (options.year === "hidden") {
            options.year = undefined
        }
        else {
            options.year = options.year ?? "numeric";
        }
        options.month = options.month ?? "numeric";
        options.day = options.day ?? "numeric";

        return date.toLocaleDateString("en-GB", {
            year: options.year,
            month: options.month,
            day: options.day});
    }

    static getTimeForDisplay(date) {
        let s = date.indexOf('T')+1;
        let e = date.lastIndexOf(':00');
        return date.substring( s,e);
    }

    static formatTimeForDisplay(date) {
        let HH = date.getHours() ;
        let mm = date.getMinutes()
        return HH+":"+mm;
    }
    static  getDateTimeForDisplay(datestr ){
        let date = new Date(datestr);
        return dateFormat(date, "mmm dS, HH:MM")
    }

    static formatDayForDisplay(date) {
        return date.toLocaleDateString("en-GB", {weekday: "long"});
    }

}
export default helperFunctions;
