import React, {useEffect, useState} from "react";
import Api from "../../../Api";
import Loading from "../../common/loading/loading";

const TermFilter = (props) => {

    const [termData, setTermData] = useState({
        isLoading: true,
        terms: [],
        selectedIds: []
    });

    useEffect(() => {
        loadTerms();
    }, []);

    function loadTerms() {
        let userToken = '';
        Api.getData(process.env.REACT_APP_API_URL + `frontend/getterms`, userToken)
            .then(data => {
                if (data !== undefined) {
                    setTermData({...termData,
                        isLoading: false,
                        terms: data
                    });
                } else {
                    console.log('failed to load terms. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to load terms');
                console.log(error);
            });
    }

    function handleFilterClick(key,value) {

        if (termData.selectedIds.filter(x => x === value).length === 0) {
            // add
            setTermData({
                ...termData,
                selectedIds: [...termData.selectedIds.filter(x => x !== value), value]
            });
        } else {
            // remove
            setTermData({
                ...termData,
                selectedIds: [...termData.selectedIds.filter(x => x !== value)]
            });
        }
        props.OnSearch(key,value);

    }

    return  (
        <div className="term-filter filter">
            <h3>Terms</h3>
            {(!termData.isLoading)? (
                <>
                    {(termData.terms !== null &&  termData.terms.length > 0) ?(
                        <ul>
                            {(termData.terms.map(termItem => (
                                <li className={"term-name"} key={termItem.termId} onClick={()=>{ handleFilterClick("term",termItem.termId) }}>

                                    {(termData.selectedIds.filter(x => x === termItem.termId).length === 0)? (
                                        <img src="/assets/images/unchecked.svg" alt=""/>
                                    ) : (
                                        <img src="/assets/images/checked.svg" alt=""/>
                                    )}

                                    <span>{termItem.name}</span>
                                </li>
                            )))}
                        </ul>
                    ):(
                        <div>No terms found</div>
                    )
                    }
                </>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default TermFilter;
