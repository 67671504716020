import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../../../Api";
import Page from "../../layouts/page";
import Progress from "../Progress/progress";


const Confirmation = () => {

    const navigate = useNavigate();
    const [pageData, setPageData] = useState({
        isLoading: true,
        data:[]
    });
    const [cartItems, setCartItems] = useState({
        items:[],
        studentdetails:[],
        contactdetails:[],
        isloading:true
    });
    const [showNext, setShowNext] = useState(false);

    useEffect(() => {
        loadCart();
    }, [])
    useEffect(() => {
        if(cartItems.isloading !== undefined && !cartItems.isloading) {
            loadPage(cartItems);
        }
    }, [cartItems.isloading]);

    useEffect(() => {
        if(cartItems.date !== undefined) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.date]);

    function isCartExpired(cart) {
        let now =  new Date();
        if(cart === null || cart === undefined || cart.date === undefined) {
            return true;
        }
        else {

            let cartdate = new Date(cart.date)
            cartdate.setDate(cartdate.getDate() + 1);
            if(cartdate < now) {
                return true;
            }
        }
        return false;
    }

    function loadCart () {
        let cart = JSON.parse(window.localStorage.getItem("cart"));
        if (cart != null) {
            if(!isCartExpired(cart)) {
                setCartItems({
                    ...cartItems,
                    items: cart.items,
                    studentdetails: cart.studentdetails,
                    contactdetails: cart.contactdetails,
                    isloading: false,
                    date: cart.date
                });
            }
            else {
                let now = new Date().toString();
                cart.items = [];
                cart.studentdetails= [];
                cart.contactdetails= []
                cart.isloading= false;
                cart.clear= false;
                cart.cartupdated= false;
                cart.date= now;


                setCartItems({
                    ...cartItems,
                    items: [],
                    studentdetails: [],
                    contactdetails: [],
                    isloading: false,
                    clear: false,
                    cartupdated: false,
                    date: now
                });
            }
        }

        return cart;
    }


    function loadPage(cart) {
        if(cart === null || cart === undefined || cart.items === null || cart.items.length === 0 || cart.studentdetails === null || cart.studentdetails.length ===0 || cart.contactdetails == null || cart.contactdetails.length === 0) {
            navigate('/classes');
        }
        else if(!IsLoggedIn()) {
            navigate('/?returnurl=/checkout/confirmation');
        }
        else {
            setPageData({...pageData, isLoading: true});


            let cartdata = {
                userid:     sessionStorage.getItem('userid'),
                token: sessionStorage.getItem('twtoken') ,
                cartitems: cart.contactdetails
            };

            Api.postAuthorizedData(process.env.REACT_APP_API_URL + `frontend/createcart`,cartdata, sessionStorage.getItem('twtoken') )
                .then(data => {
                    if (data !== undefined && (data.status !== 400 && data.Status !== 400)) {
                        setPageData({...pageData, isLoading: false, data: data});
                    } else {

                    }
                })
                .catch(error => {

                });
        }
    }
    function IsLoggedIn() {
        let userid = sessionStorage.getItem('userid');
        let token =     sessionStorage.getItem('twtoken');
        if(userid !== undefined && userid !== null && token !== undefined && token !== null) {
            return true;
        }
        return  false;
    }

    const OnNext = (e) => {
        e.preventDefault();

        let data = {
            userid:     pageData.data.userId,
            token: sessionStorage.getItem('twtoken') ,
            orderid: pageData.data.orderId,
            accepttermsandconditions: e.currentTarget[0].checked,
            paymentgatewayid:pageData.data.paymentGatewayId
        };

        Api.postAuthorizedData(process.env.REACT_APP_API_URL + `frontend/accepttermsandconditions`,data, sessionStorage.getItem('twtoken') )
        .then(data => {
            if (data !== undefined && (data.status !== 400 || data.Status !== 400)) {
                window.localStorage.setItem("cart", JSON.stringify(cartItems));
                navigate('/checkout/payment/'+data.orderId+'/'+pageData.data.paymentGatewayId);
            } else {
                var c ='';
            }
        })
        .catch(error => {
            var c ='';
        });
    }
    const OnCancel = (e) => {
        navigate('/checkout/contacts');
    }
    const OnAcceptTermsAndConditions = (e) => {
        if(e.currentTarget.checked && pageData.data !== null && pageData.data !== null  && pageData.data.paymentGatewayId !== '' && pageData.data.items.length > 0) {
            setShowNext(true);
        }
        else {
            setShowNext(false);
        }
    }
    return (
        <Page pageTitle={"Checkout / Contact Details"} metaTitle={"Confirmation | Checkout | Little House of Science"} metaDescription={"Little House of Science checkout"}  cartItems={cartItems.items} enableShowCart={false}>
            <div className="confirmation-form-component">

                <div className="inner">
                    <div className="confirmation-container">

                        <Progress step={3} />

                        {
                            <form className="form" onSubmit={OnNext} >
                                <h2>Confirmation</h2>
                                {(!pageData.isLoading) ? (
                                    <>
                                        <div className="box">

                                            <div className="table desktop-only">

                                                <div className="col t-heading">
                                                    Class
                                                </div>
                                                <div className="col t-heading">
                                                    Student details
                                                </div>
                                                <div className="col t-heading">
                                                    Class details
                                                </div>
                                                <div className="col t-heading">
                                                    Amount
                                                </div>

                                                {(pageData.data.items.map(item => (
                                                    <>
                                                        <div className="col t-data t-class-name">
                                                            {item.class}
                                                        </div>
                                                        <div className="col t-data t-student">
                                                            <span>{item.student}</span>
                                                        </div>
                                                        <div className="col t-data t-class-details">
                                                            <span>{item.days}</span>
                                                            <span>{item.times}</span>
                                                        </div>
                                                        <div className="col t-data t-price">
                                                            {
                                                                (item.amount.Price === item.amount.Total) ?
                                                                    '£' + item.amount.Price
                                                                    : (
                                                                        <>
                                                                            <span>Fee <span
                                                                                className="amount-value">£{item.amount.Price}</span></span><br/>
                                                                            {(item.amount.Discount !== undefined) ? <>
                                                                                <span>Discount <span
                                                                                    className="amount-value">£{item.amount.Discount}</span></span><br/></> : ''}
                                                                            {(item.amount.EarlyDropoffFee !== undefined) ? <>
                                                                                <span>Early Drop Off Fee <span
                                                                                    className="amount-value">£{item.amount.EarlyDropoffFee}</span></span><br/></> : ''}
                                                                            {(item.amount.LatePickupFee !== undefined) ? <>
                                                                                <span>Late Pickup Fee <span
                                                                                    className="amount-value">£{item.amount.LatePickupFee}</span></span><br/></> : ''}
                                                                            <span>Total <span
                                                                                className="amount-value">£{item.amount.Total}</span></span><br/>
                                                                        </>
                                                                    )
                                                            }
                                                        </div>
                                                    </>
                                                )))}
                                            </div>

                                            <div className="table-mobile mobile-only">



                                                {(pageData.data.items.map(item => (
                                                    <div className="tm-row">
                                                        {/*<div className="col">
                                                            <div className="t-heading">
                                                                Class
                                                            </div>
                                                            <div className="t-heading">
                                                                Student details
                                                            </div>
                                                            <div className="t-heading">
                                                                Class details
                                                            </div>
                                                            <div className="t-heading">
                                                                Amount
                                                            </div>
                                                        </div>*/}
                                                        <div className="tm-class">

                                                            <div className="t-heading">
                                                                Class
                                                            </div>
                                                            <div className="t-data t-class-name">
                                                                {item.class}
                                                            </div>

                                                            <div className="t-heading">
                                                                Student details
                                                            </div>
                                                            <div className="t-data t-student">
                                                                <span>{item.student}</span>
                                                            </div>

                                                            <div className="t-heading">
                                                                Class details
                                                            </div>
                                                            <div className="t-data t-class-details">
                                                                <span>{item.days}</span>
                                                                <span>{item.times}</span>
                                                            </div>

                                                            <div className="t-heading">
                                                                Amount
                                                            </div>
                                                            <div className="t-data t-price">
                                                                {
                                                                    (item.amount.Price === item.amount.Total) ?
                                                                        '£' + item.amount.Price
                                                                        : (
                                                                            <>
                                                                            <span>Fee <span
                                                                                className="amount-value">£{item.amount.Price}</span></span><br/>
                                                                                {(item.amount.Discount !== undefined) ? <>
                                                                                <span>Discount <span
                                                                                    className="amount-value">£{item.amount.Discount}</span></span><br/></> : ''}
                                                                                {(item.amount.EarlyDropoffFee !== undefined) ? <>
                                                                                <span>Early Drop Off Fee <span
                                                                                    className="amount-value">£{item.amount.EarlyDropoffFee}</span></span><br/></> : ''}
                                                                                {(item.amount.LatePickupFee !== undefined) ? <>
                                                                                <span>Late Pickup Fee <span
                                                                                    className="amount-value">£{item.amount.LatePickupFee}</span></span><br/></> : ''}
                                                                                <span>Total <span
                                                                                    className="amount-value">£{item.amount.Total}</span></span><br/>
                                                                            </>
                                                                        )
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                )))}

                                            </div>

                                            <div className="total-container t-row">
                                                <div className="t-data">
                                                    <div className="field">
                                                        <span className="f-label">Items Total:</span>
                                                        <span className="f-value"> £{pageData.data.itemsTotal}</span>
                                                    </div>
                                                    {(pageData.data.discountsTotal !== undefined && pageData.data.discountsTotal > 0) ?
                                                        (
                                                            <div className="field">
                                                            <span className="f-label">Discounts Total:</span>
                                                                <span
                                                                    className="f-value">£{pageData.data.discountsTotal}</span>
                                                            </div>
                                                        )
                                                        : null}
                                                    <div className="field">
                                                        <span className="f-label">Total:</span>
                                                        <span className="f-value">£{pageData.data.total}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : ""}

                                <div className="termsandconditions-container">
                                    <input type="checkbox" onChange={OnAcceptTermsAndConditions}/>
                                    <a href="http://littlehouseofscience.linux2.lilo.cloud/terms-and-conditions/"
                                       target="_blank" rel="noreferrer">Accept terms and conditions</a> <br/>
                                </div>
                                <div className="action-container">
                                    <button className="class-action btn cancel" onClick={() => OnCancel()}>Back</button>
                                    {(showNext) ?
                                        <button className="class-action btn orange" type="submit">Next</button>
                                        :
                                        <div className="class-action btn disabled">Next</div>
                                    }
                                </div>
                            </form>
                        }

                    </div>
                </div>
            </div>
        </Page>
    )
}
export default Confirmation;
