import React, {useState} from "react";
import Header from "./header";
import Footer from "./footer";
import {Helmet, HelmetProvider} from 'react-helmet-async';
const Page = (props) => {

    return (
        <HelmetProvider>
            <Helmet>
                {(props.metaTitle !== undefined)? (
                <title>{props.metaTitle}</title>
                ) : null}
                {(props.metaDescription !== undefined)? (
                <meta name='description' content={props.metaDescription} />
                ) : null}
            </Helmet>
        <div className="template-layout-page">
            <div className="inner-wrap">
                <Header pageTitle={props.pageTitle} cartItems={props.cartItems} updateCart={props.updateCart} enableShowCart={props.enableShowCart}  clearCart={props.clearCart} />
                <div className="page-components">
                    {props.children}
                </div>
                <Footer />
            </div>
        </div>
        </HelmetProvider>
    )
}
export default Page;
