import React, {useState} from "react";

const DayFilter = (props) => {

    const [selectedDays, setSelectedDays] = useState({
        days: []
    });

    function toggleDays(day) {
        let daysSelected = selectedDays.days;

        // check if day is already in the list
        if (daysSelected.filter(x => x === day).length > 0) {
            // is in list, remove it
            daysSelected = daysSelected.filter(x => x !== day);
        } else {
            // not in the list, add it
            daysSelected = [...daysSelected, day];
        }

        // update the state array
        setSelectedDays({
            ...selectedDays,
            days: daysSelected
        })
    }

    return  <div className="day-filter filter">
            <h3>Days</h3>

                <ul>

                    <li className="Monday" key="1" onClick={() => {
                        props.OnSearch("day", 1);
                        toggleDays(1);
                    }}>
                        {(selectedDays.days.filter(x => x === 1).length === 0)? (
                            <img src="/assets/images/unchecked.svg" alt=""/>
                        ) : (
                            <img src="/assets/images/checked.svg" alt=""/>
                        )}
                        <span>Monday</span>
                    </li>
                    <li className="Tuesday" key="2" onClick={() => {
                        props.OnSearch("day", 2);
                        toggleDays(2);
                    }}>
                        {(selectedDays.days.filter(x => x === 2).length === 0)? (
                            <img src="/assets/images/unchecked.svg" alt=""/>
                        ) : (
                            <img src="/assets/images/checked.svg" alt=""/>
                        )}
                        <span>Tuesday</span>
                    </li>
                    <li className="Wednesday" key="3" onClick={() => {
                        props.OnSearch("day", 3);
                        toggleDays(3);
                    }}>
                        {(selectedDays.days.filter(x => x === 3).length === 0)? (
                            <img src="/assets/images/unchecked.svg" alt=""/>
                        ) : (
                            <img src="/assets/images/checked.svg" alt=""/>
                        )}
                        <span>Wednesday</span>
                    </li>
                    <li className="Thursday" key="4" onClick={() => {
                        props.OnSearch("day", 4);
                        toggleDays(4);
                    }}>
                        {(selectedDays.days.filter(x => x === 4).length === 0)? (
                            <img src="/assets/images/unchecked.svg" alt=""/>
                        ) : (
                            <img src="/assets/images/checked.svg" alt=""/>
                        )}
                        <span>Thursday</span>
                    </li>
                    <li className="Friday" key="5" onClick={() => {
                        props.OnSearch("day", 5);
                        toggleDays(5);
                    }}>
                        {(selectedDays.days.filter(x => x === 5).length === 0)? (
                            <img src="/assets/images/unchecked.svg" alt=""/>
                        ) : (
                            <img src="/assets/images/checked.svg" alt=""/>
                        )}
                        <span>Friday</span>
                    </li>
                    <li className="Saturday" key="6" onClick={() => {
                        props.OnSearch("day", 6);
                        toggleDays(6);
                    }}>
                        {(selectedDays.days.filter(x => x === 6).length === 0)? (
                            <img src="/assets/images/unchecked.svg" alt=""/>
                        ) : (
                            <img src="/assets/images/checked.svg" alt=""/>
                        )}
                        <span>Saturday</span>
                    </li>
                    <li className="Sunday" key="7" onClick={() => {
                        props.OnSearch("day", 7);
                        toggleDays(7);
                    }}>
                        {(selectedDays.days.filter(x => x === 7).length === 0)? (
                            <img src="/assets/images/unchecked.svg" alt=""/>
                        ) : (
                            <img src="/assets/images/checked.svg" alt=""/>
                        )}
                        <span>Sunday</span>
                    </li>

                </ul>

    </div>;

}

export default DayFilter;
