import React, { useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import Api from "../../Api";
import Page from "../layouts/page";
const Registered = () => {
    const [cartItems, setCartItems] = useState({
        items:[],
        studentdetails:[],
        contactdetails:[],
        isloading:true,
        next:false
    });

    useEffect(() => {
        loadCart();
    }, [])

    const navigate = useNavigate();

    useEffect(() => {
        if(cartItems.date !== undefined) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.date]);

    function isCartExpired(cart) {
        let now =  new Date();
        if(cart === null || cart === undefined || cart.date === undefined) {
            return true;
        }
        else {

            let cartdate = new Date(cart.date)
            cartdate.setDate(cartdate.getDate() + 1);
            if(cartdate < now) {
                return true;
            }
        }
        return false;
    }

    function loadCart () {
        let cart = JSON.parse(window.localStorage.getItem("cart"));
        if (cart != null) {
            if (!isCartExpired(cart)) {
                setCartItems({
                    ...cartItems,
                    items: cart.items,
                    studentdetails: cart.studentdetails,
                    contactdetails: cart.contactdetails,
                    isloading: false,
                    next: false,
                    date: cart.date
                });
            }
            else {
                let now = new Date().toString();
                cart.items = [];
                cart.studentdetails= [];
                cart.contactdetails= []
                cart.isloading= false;
                cart.clear= false;
                cart.cartupdated= false;
                cart.date= now;


                setCartItems({
                    ...cartItems,
                    items: [],
                    studentdetails: [],
                    contactdetails: [],
                    isloading: false,
                    clear: false,
                    cartupdated: false,
                    date: now
                });
            }
        }

        return cart;
    }
    const isLoggedIn = () => {
        let userid = sessionStorage.getItem('userid');
        let token =     sessionStorage.getItem('twtoken');
        if(userid !== undefined && userid !== null && token !== undefined && token !== null) {
            return true;
        }
        return  false;
    }

    return (
        <Page pageTitle={"Login/Registered"} cartItems={cartItems.items} enableShowCart={true}>
            <div className="registered-form-component">

                <div className="inner">
                    <div className="registered-container">
                        <h2>Registered</h2>
                        <span>Your account has been registered and you've been logged in.</span>
                        {(cartItems !== undefined && cartItems.items !== undefined && cartItems.items.length > 0 && isLoggedIn()) ?(
                        <>
                            <br/><br/>
                           <span>Please <Link to='/checkout/details'>click here</Link> to continue with checkout.</span>
                        </>)
                        : ''}
                    </div>
                </div>

            </div>
        </Page>
    )
}
export default Registered;
