const Progress = (props) => {

    function currentStepName() {
        let stepNumber = props.step;
        let stepName = '';
        if (stepNumber === 1) {
            stepName = 'Students';
        } else if (stepNumber === 2) {
            stepName = 'Contact';
        }
        else if (stepNumber === 3) {
            stepName = 'Confirmation';
        }
        else if (stepNumber === 4) {
            stepName = 'Payment';
        }
        else if (stepNumber === 5) {
            stepName = 'Complete';
        }

        return stepName;
    }

    return (
        <div className="progress-component">
            <div className="inner">
                <div className="mobile-progress">
                    <div className={"m-tick"}>
                        <img
                            src={"/assets/images/step-tick.png"}
                            alt=""/>
                    </div>
                    <div className={"m-step"}>Step {props.step} / 5</div>
                    <div className="m-step-name">
                        - {currentStepName()}
                    </div>
                </div>
                <div className="step-visual">
                    <div
                        className={"step " + ((props.step === 1) ? "current" : "") + ((props.step > 1) ? "past-step" : "")}>
                        <div className="line hide"></div>
                        <img
                            src={((props.step > 1) ? "/assets/images/step-tick.png" : ((props.step === 1) ? "/assets/images/step-selected.png" : "/assets/images/step.png"))}
                            alt=""/>
                        <div className="line"></div>
                    </div>
                    <div className={"step " + ((props.step === 2)? "current": "") + ((props.step > 2)? "past-step": "") }>
                        <div className="line"></div>
                        <img src={((props.step > 2)? "/assets/images/step-tick.png" : ( (props.step === 2)? "/assets/images/step-selected.png": "/assets/images/step.png" ) )} alt=""/>
                        <div className="line"></div>
                    </div>
                    <div className={"step " + ((props.step === 3)? "current": "") + ((props.step > 3)? "past-step": "") }>
                        <div className="line"></div>
                        <img src={((props.step > 3)? "/assets/images/step-tick.png" : ( (props.step === 3)? "/assets/images/step-selected.png": "/assets/images/step.png" ) )} alt=""/>
                        <div className="line"></div>
                    </div>
                    <div className={"step " + ((props.step === 4)? "current": "") + ((props.step > 4)? "past-step": "") }>
                        <div className="line"></div>
                        <img src={((props.step > 4)? "/assets/images/step-tick.png" : ( (props.step === 4)? "/assets/images/step-selected.png": "/assets/images/step.png" ) )} alt=""/>
                        <div className="line"></div>
                    </div>
                    <div className={"step " + ((props.step === 5)? "current": "") + ((props.step === 5)? "past-step": "") }>
                        <div className="line"></div>
                        <img src={((props.step === 5)? "/assets/images/step-tick.png" : ( (props.step === 5)? "/assets/images/step-selected.png": "/assets/images/step.png" ) )} alt=""/>
                        <div className="line"></div>
                    </div>

                </div>
                <div className="step-names">
                    <div className="step-name step-one">Students</div>
                    <div className="step-name step-two">Contact</div>
                    <div className="step-name step-three">Confirmation</div>
                    <div className="step-name step-four">Payment</div>
                    <div className="step-name step-five">Complete</div>
                </div>
            </div>
        </div>
    )
}
export default Progress;
