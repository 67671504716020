import React, {useEffect, useState} from "react";
import Api from "../../../Api";
import Loading from "../../common/loading/loading";

const Banner = (props) => {

    const wpEndPoint = 'http://littlehouseofscience.linux2.lilo.cloud/wp-json/enrolments/v1/';

    const [banner, setBanner] = useState({
        isLoading: true,
        unsafeHtml:[]
    });

    useEffect(() => {
       // loadBanner();
    },[]);

    function loadBanner() {
        // process.env.REACT_WORDPRESS_API_URL
        Api.getData(`${wpEndPoint}class/banner/1`)
            .then(data => {
                if (data !== undefined) {
                    setBanner({...banner,
                        isLoading: false,
                        unsafeHtml: data
                    });
                } else {
                    console.log('failed to load class banner. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to load class banner');
                console.log(error);
            });
    }

    return (
        <>
            {(!banner.isLoading)? (
            <div className="class-banner-component" dangerouslySetInnerHTML={{__html: banner.unsafeHtml}}></div>
            ) : (
            <Loading/>
            )}
        </>
    )

}
export default Banner;
