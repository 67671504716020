import React, {useEffect, useState} from "react";
import Api from "../../../Api";
import Loading from "../../common/loading/loading";

const CourseFilter = (props) => {

    const [courseData, setCourseData] = useState({
        isLoading: true,
        courses: [],
        selectedIds: []
    });


    useEffect(() => {
        loadCourses();
    }, []);

    function loadCourses() {
        // Get the Data from the API
        let userToken = '';
        Api.getData(process.env.REACT_APP_API_URL + `frontend/getcourses`, userToken)
            .then(data => {
                if (data !== undefined) {
                    // Store the data from the API. below is an example
                    setCourseData({...courseData,
                        isLoading: false,
                        courses: data
                    });
                } else {
                    console.log('failed to load courses. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to load courses');
                console.log(error);
            });
    }

    function handleFilterClick(key,value) {

        if (courseData.selectedIds.filter(x => x === value).length === 0) {
            // add
            setCourseData({
                ...courseData,
                selectedIds: [...courseData.selectedIds.filter(x => x !== value), value]
            });
        } else {
            // remove
            setCourseData({
                ...courseData,
                selectedIds: [...courseData.selectedIds.filter(x => x !== value)]
            });
        }
        props.OnSearch(key,value);

    }

    return  <div className="course-filter filter">
            <h3>Courses</h3>
            {(!courseData.isLoading)? (
                <>
                    {(courseData.courses !== null &&  courseData.courses.length > 0) ?(
                        <ul>
                            {(courseData.courses.map(courseItem => (

                                <li className={"course-name"} key={courseItem.courseId} onClick={()=>{ handleFilterClick("course", courseItem.courseId) }}>
                                    {(courseData.selectedIds.filter(x => x === courseItem.courseId).length === 0)? (
                                        <img src="/assets/images/unchecked.svg" alt=""/>
                                    ) : (
                                        <img src="/assets/images/checked.svg" alt=""/>
                                    )}
                                    <span>{courseItem.name}</span>
                                </li>

                            )))}
                        </ul>
                    ):(
                        <div>No courses found</div>
                    )
                    }
                </>
            ) : (
                <Loading />
            )}

        </div>;

}

export default CourseFilter;
