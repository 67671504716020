import React, { useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
const Account = (props) => {

    const [pageData, setPageData] = useState({
        isLoading: true,
        user:[]
    });

    useEffect(() => {
        loadCart();
    }, []);
    useEffect(() => {
        if(props.cartItems !== undefined && !props.cartItems.isloading) {
            loadPage(props.cartItems);
        }
    }, [props.cartItems]);

    const navigate = useNavigate();
    const removeItem = (id) => {
            props.updateCart(id);
    }

    function loadCart () {
        let cart = props.cartItems;
        loadPage(cart);
    }
    function loadPage(cart) {

        let userData  = sessionStorage.getItem('user');
        let user = JSON.parse(userData);
        setPageData({
            ...pageData,
            user: user,
            isloading:false,
        });
    }

    const isLoggedIn = () => {
        let userid = sessionStorage.getItem('userid');
        let token =     sessionStorage.getItem('twtoken');
        if(userid !== undefined && userid !== null && token !== undefined && token !== null) {
            return true;
        }
        return  false;
    }

    function onLogout() {
        sessionStorage.removeItem('twtoken');
        sessionStorage.removeItem('userid');
        sessionStorage.removeItem('user');
        navigate('/classes');
    }

    return (
        (isLoggedIn()) ?  <div className="account-form-component">

            <div className="inner">
                <div className="account-container">
                    <div>
                        {/*<div className="ac-top-bar">
                                <div className="modal-close-btn" onClick={props.CloseAccountDialog}>
                                    <img src="/assets/images/cross.svg" alt="Close" loading={"lazy"} width={20} height={20}/>
                                </div>
                                <div className="account-name">Account {(pageData.user !== undefined && pageData.user.firstName !== undefined) ?pageData.user.firstName:'' }</div>
                            </div>*/}
                        <div
                            className="account-name">Hello <span className="acc-name">{(pageData.user !== undefined && pageData.user.firstName !== undefined) ? pageData.user.firstName : ''}</span></div>
                        <div className="action-container">
                            <div className="class-action btn orange" onClick={onLogout}>Log out</div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : null
    )
}
export default Account;
