import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../../Api";
import Page from "../layouts/page";
import Required from "../common/Required";
import ForgottenPassword from "./ForgottenPassword";
import Cart from "../layouts/Cart";
const Login = () => {

    useEffect(() => {
        GetAdvertisingSources();
    }, [])

    const navigate = useNavigate();
    const params = new URLSearchParams(document.location.search);

    const [formValidation, setFormValidation] = useState({
        isLoading: false,
        showFirstNameValidationError: false,
        showLastNameValidationError: false,
        showTelephoneValidationError: false,
        showMobileValidationError: false,
        showEmailValidationError: false,
        showPasswordValidationError: false,
        showConfirmPasswordValidationError: false,
        showPasswordMatchValidationError: false,
        showApiError: false
    });

    const [advertisingSourcesData, setAdvertisingSources] = useState({
        isLoading: false,
        advertisingsources:[]
    });
    const [formAdvertisingSourcesErrors, setFormAdvertisingSourcesErrors] = useState({
        hasErrors: false,
        errors:[]
    });

    const [passwordReveal, setPasswordReveal] = useState({
        image: 'eye.svg',
        type: 'password'
    });

    const [passwordRegistrationReveal, setPasswordRegistrationReveal] = useState({
        image: 'eye.svg',
        type: 'password'
    });


    const onFormSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const emailAddress = formData.get('emailAddress');
        const password = formData.get('password');

        setFormValidation({...formValidation,
            showEmailValidationError: (emailAddress.length === 0),
            showPasswordValidationError: (password.length === 0)
        });

        if (emailAddress.length > 0 && password.length > 0) {
            setFormValidation({...formValidation, isLoading: true, showEmailValidationError: false, showPasswordValidationError: false,showApiError: false});
            Api.postData(process.env.REACT_APP_API_URL + `User/login`, {
                emailAddress: emailAddress,
                password: password
            })
                .then(data => {
                    if (data !== undefined && data.Status !== 400) {
                        sessionStorage.setItem('twtoken', data.token);
                        sessionStorage.setItem('userid', data.user.userId);
                        sessionStorage.setItem('user', JSON.stringify(data.user));
                        // redirect the user somewhere
                        let returnurl = params.get("returnurl");
                        if(returnurl !== undefined && returnurl !== null && returnurl.length > 0) {
                            navigate('/'+returnurl);
                        }
                        else {
                            navigate('/classes');
                        }
                    } else {
                        setFormValidation({...formValidation, isLoading: false, showEmailValidationError: false, showPasswordValidationError: false, showApiError: true});
                        console.log('login failed. Data is undefined.');
                    }
                })
                .catch(error => {
                    setFormValidation({...formValidation, isLoading: false, showApiError: true});
                    console.log('Login Failed');
                });
        }
    }

    const onRegister = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const firstname = formData.get('tbFirstName');
        const lastname = formData.get('tbLastName');
        const telephone = formData.get('tbTelephone');
        const mobile = formData.get('tbMobile');
        const advertisersource = formData.get('ddlAdvertising');
        const password = formData.get('tbPassword');
        const confirmpassword = formData.get('tbConfirmPassword');
        const emailaddress = formData.get('tbEmailaddress');

        let showFirstNameValidationError = (firstname.length === 0);
        let showLastNameValidationError = (lastname.length === 0);
        let showMobileValidationError = (mobile.length === 0);
        let showTelephoneValidationError = (telephone.length === 0);
        let showEmailValidationError = (emailaddress.length === 0);
        let showPasswordValidationError = (password.length === 0);
        let showConfirmPasswordValidationError = (confirmpassword.length === 0);
        let showPasswordMatchValidationError = (password !== confirmpassword && !showConfirmPasswordValidationError );

        setFormValidation({...formValidation,
            showFirstNameValidationError: showFirstNameValidationError,
            showLastNameValidationError: showLastNameValidationError,
            showMobileValidationError: showMobileValidationError,
            showTelephoneValidationError: showTelephoneValidationError,
            showEmailValidationError: showEmailValidationError,
            showPasswordValidationError: showPasswordValidationError,
            showConfirmPasswordValidationError: showConfirmPasswordValidationError,
            showPasswordMatchValidationError: showPasswordMatchValidationError,
        });

        if (!showFirstNameValidationError&&
            !showLastNameValidationError &&
            !showMobileValidationError &&
            !showTelephoneValidationError &&
            !showEmailValidationError &&
            !showPasswordValidationError &&
            !showConfirmPasswordValidationError&&
            !showPasswordMatchValidationError
            ) {
            setFormValidation({...formValidation, isLoading: true,showFirstNameValidationError:false,showLastNameValidationError:false,showMobileValidationError:false,showTelephoneValidationError:false, showEmailValidationError: false, showPasswordValidationError: false,showApiError: false});
            Api.postData(process.env.REACT_APP_API_URL + `frontend/registeruser`, {
                firstname: firstname, lastname: lastname, emailaddress: emailaddress,telephone:telephone, mobile: mobile,  password: password, advertisingsourcecode: advertisersource
            })
            .then(data => {
                if (data !== undefined && data.Status !== 400) {
                    sessionStorage.setItem('twtoken', data.token);
                    sessionStorage.setItem('userid', data.user.userId);
                    sessionStorage.setItem('user', JSON.stringify(data.user));
                    // redirect the user somewhere
                    navigate('/account/registered');
                }
                else if(data !== undefined && data.Status === 400) {

                    let errors = []
                    if(data.Errors !== undefined) {
                        if (data.Errors['user:requiredfirstname'] !== undefined) {
                            errors.push({error : 'requiredfirstname',message : data.Errors['user:requiredfirstname'][0] });
                        }
                        else if (data.Errors['user:requiredlastname'] !== undefined) {
                            errors.push({error : 'requiredlastname',message : data.Errors['user:requiredlastname'][0] });
                        }
                        else if (data.Errors['user:requiredemailaddress']!== undefined) {
                            errors.push({error : 'requiredemailaddress',message : data.Errors['user:requiredemailaddress'][0] });
                        }
                        else if (data.Errors['user:emailaddressexists'] !== undefined) {
                            errors.push({error : 'emailaddressexists',message : data.Errors['user:emailaddressexists'][0] });
                        }
                        else if (data.Errors['user:requiredmobile'] !== undefined) {
                            errors.push({error : 'requiredmobile',message : data.Errors['user:requiredmobile'][0] });
                        }
                        else if (data.Errors['user:passwordrequirements'] !== undefined) {
                            errors.push({error : 'passwordrequirements',message : data.Errors['user:passwordrequirements'][0] });
                        }

                    }

                    setFormAdvertisingSourcesErrors({...formAdvertisingSourcesErrors, hasErrors: true, errors:errors})
                    setFormValidation({...formValidation, isLoading: false, showEmailValidationError: false, showPasswordValidationError: false, showApiError: true});
                }
                else {
                    setFormValidation({...formValidation, isLoading: false, showEmailValidationError: false, showPasswordValidationError: false, showApiError: true});
                    console.log('login failed. Data is undefined.');
                }
            })
            .catch(error => {
                setFormValidation({...formValidation, isLoading: false, showApiError: true});
                console.log('Login Failed');
            });
        }
    }

    function GetAdvertisingSources() {
        let userToken = '';
        Api.getData(process.env.REACT_APP_API_URL + `frontend/getadvertisingsources`, userToken)
            .then(data => {
                if (data !== undefined) {
                    setAdvertisingSources({...advertisingSourcesData,
                        isLoading: false,
                        advertisingsources: data
                    });
                } else {
                    console.log('failed to load advertising sources. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to load advertising sources');
                console.log(error);
            });
    }

    function ShowForgottenPasswordDialog() {
        let dialog = document.getElementById('viewForgottenPasswordModal')
        dialog.showModal();
    }

    function CloseForgottenPasswordDialog() {
        let dialog = document.getElementById('viewForgottenPasswordModal')
        dialog.close();
    }

    function onPasswordReveal() {
     if(passwordReveal.image==='eye.svg')
     {
         setPasswordReveal({...passwordReveal,
             image: 'eye-off.svg',
             type: 'text'
         });
     }
     else {
         setPasswordReveal({...passwordReveal,
             image: 'eye.svg',
             type: 'password'
         });
     }
    }

    function onPasswordRegistrationReveal() {
        if(passwordRegistrationReveal.image==='eye.svg')
        {
            setPasswordRegistrationReveal({...passwordRegistrationReveal,
                image: 'eye-off.svg',
                type: 'text'
            });
        }
        else {
            setPasswordRegistrationReveal({...passwordRegistrationReveal,
                image: 'eye.svg',
                type: 'password'
            });
        }
    }

    return (
        <Page pageTitle={"Login/Register"} metaTitle={"Little House of Science - Login/Register"} metaDescription={"Little House of Science - Login/Register"}>

            <div className="login-form-component">

                <div className="inner">
                    <div className="login-container">
                        <h2>Login</h2>
                        <form className="form" onSubmit={onFormSubmit} >

                        <div className="field-row">
                            <label htmlFor="emailAddress">Email Address</label>
                            <input type="email" name="emailAddress" id="emailAddress"  />
                            {(formValidation.showEmailValidationError)? (
                                <div className="validation-error">Please enter your email address</div>
                            ) : null }
                        </div>

                        <div className="field-row">
                            <label htmlFor="password">Password</label>
                            <div className="password-container">
                                <input type={passwordReveal.type} name="password" id="password" />      <img className="password-icon" onClick={onPasswordReveal} src={'/assets/images/'+passwordReveal.image} />
                            </div>
                            {(formValidation.showPasswordValidationError)? (
                                <div className="validation-error">Please enter your password</div>
                            ) : null}
                        </div>

                        <div className="field-row actions">
                            {(!formValidation.isLoading)? (
                                <>
                                <button type="submit" className="btn-login btn orange">Login</button>
                                <button  type="button" onClick={ShowForgottenPasswordDialog} className="btn-login btn cancel">Forgotten Password</button>
                                </>
                            ) : null}

                            {(formValidation.showApiError)? (
                                <div className="validation-error">Login Failed</div>
                            ) : null}
                        </div>

                    </form>
                    </div>
                    <div className="registration-container">
                        <h2>Register</h2>
                        <form className="form" onSubmit={onRegister}>
                            <div className="field-row two-column">
                                <div className="row-6">
                                    <label htmlFor="tbFirstName">First Name</label><Required/>
                                    <input type="text" name="tbFirstName" id="tbFirstName"  />
                                    {(formValidation.showFirstNameValidationError)? (
                                        <div className="validation-error">Please enter your first name</div>
                                    ) : null }
                                    {(formAdvertisingSourcesErrors.hasErrors && formAdvertisingSourcesErrors.errors.filter(x => x.error === 'requiredfirstname').length > 0)? (
                                        <div className="validation-error">{formAdvertisingSourcesErrors.errors.filter(x => x.error === 'requiredfirstname')[0].message}</div>
                                    ) : null }
                                </div>
                                <div className="row-6">
                                    <label htmlFor="tbLastName">Last Name</label><Required/>
                                    <input type="text" name="tbLastName" id="tbLastName"  />
                                    {(formValidation.showLastNameValidationError)? (
                                        <div className="validation-error">Please enter your last name</div>
                                    ) : null }

                                    {(formAdvertisingSourcesErrors.hasErrors && formAdvertisingSourcesErrors.errors.filter(x => x.error === 'requiredlastname').length > 0)? (
                                        <div className="validation-error">{formAdvertisingSourcesErrors.errors.filter(x => x.error === 'requiredlastname')[0].message}</div>
                                    ) : null }

                                </div>
                            </div>


                            <div className="field-row two-column">
                                <div className="row-6">
                                    <label htmlFor="tbTelephone">Telephone</label><Required/>
                                    <input type="text" name="tbTelephone" id="tbTelephone"  />
                                    {(formValidation.showTelephoneValidationError)? (
                                        <div className="validation-error">Please enter your telephone number</div>
                                    ) : null }
                                </div>
                                <div className="row-6">
                                    <label htmlFor="tbMobile">Mobile</label><Required/>
                                    <input type="text" name="tbMobile" id="tbMobile"  />
                                    {(formValidation.showMobileValidationError)? (
                                        <div className="validation-error">Please enter your mobile number</div>
                                    ) : null }

                                    {(formAdvertisingSourcesErrors.hasErrors && formAdvertisingSourcesErrors.errors.filter(x => x.error === 'requiredmobile').length > 0)? (
                                        <div className="validation-error">{formAdvertisingSourcesErrors.errors.filter(x => x.error === 'requiredmobile')[0].message}</div>
                                    ) : null }

                                </div>
                            </div>


                            <div className="field-row">
                                <div className="row-12">
                                    <label htmlFor="ddlAdvertising">Where did you hear about us</label>
                                    <select id="ddlAdvertising">
                                        {(!advertisingSourcesData.isLoading)? (

                                            (
                                                advertisingSourcesData.advertisingsources.map(source => (
                                                <option key={source.categoryItemId} value={source.code}>{source.name}</option>
                                            ))))
                                            : <option key="">loading advertising sources...</option>}
                                    </select>
                                </div>
                            </div>

                            <div className="field-row">
                                <div className="row-12">
                                    <label htmlFor="tbEmailaddress">Email Address</label><Required/>
                                    <input type="text" name="tbEmailaddress" id="tbEmailaddress"/>
                                    {(formValidation.showEmailValidationError)? (
                                        <div className="validation-error">Please enter your email address </div>
                                    ) : null }
                                    {(formAdvertisingSourcesErrors.hasErrors && formAdvertisingSourcesErrors.errors.filter(x => x.error === 'requiredemailaddress').length > 0)? (
                                        <div className="validation-error">{formAdvertisingSourcesErrors.errors.filter(x => x.error === 'requiredemailaddress')[0].message}</div>
                                    ) : null }
                                    {(formAdvertisingSourcesErrors.hasErrors && formAdvertisingSourcesErrors.errors.filter(x => x.error === 'emailaddressexists').length > 0)? (
                                        <div className="validation-error">{formAdvertisingSourcesErrors.errors.filter(x => x.error === 'emailaddressexists')[0].message}</div>
                                    ) : null }
                                </div>
                            </div>

                            <div className="field-row">
                                <div className="row-12">
                                    <label htmlFor="tbPassword">Password</label><Required/>

                                    <div className="password-container">
                                        <input type={passwordRegistrationReveal.type} name="tbPassword" id="tbPassword"  />    <img className="password-icon" onClick={onPasswordRegistrationReveal} src={'/assets/images/'+passwordRegistrationReveal.image} />
                                    </div>
                                    {(formValidation.showPasswordValidationError)? (
                                        <div className="validation-error">Please enter your password</div>
                                    ) : null }
                                </div>
                            </div>
                            <div className="field-row">
                                <div className="row-12">
                                    <label htmlFor="tbConfirmPassword">Confirm Password</label><Required/>
                                    <div className="password-container">
                                        <input type={passwordRegistrationReveal.type} name="tbConfirmPassword" id="tbConfirmPassword"  /><img className="password-icon" onClick={onPasswordRegistrationReveal} src={'/assets/images/'+passwordRegistrationReveal.image} />
                                    </div>
                                    {(formValidation.showConfirmPasswordValidationError)? (
                                        <div className="validation-error">Please enter your confirmation password </div>
                                    ) : null }

                                    {(formValidation.showPasswordMatchValidationError)? (
                                        <div className="validation-error">Your passwords do not match</div>
                                    ) : null }
                                </div>
                            </div>
                            <div className="field-row">
                                <div className="row-12">
                                    <span className="password-info">Your password needs to contain numbers and letters and at least one capital letter and one special character %!@#$%^&*()?/>.;'\|+=</span>
                                </div>
                            </div>

                            {(formAdvertisingSourcesErrors.hasErrors && formAdvertisingSourcesErrors.errors.filter(x => x.error === 'passwordrequirements').length > 0)? (
                                <div className="field-row">
                                    <div className="row-12">
                                            <div className="validation-error">{formAdvertisingSourcesErrors.errors.filter(x => x.error === 'passwordrequirements')[0].message}</div>
                                    </div>
                                </div>
                            ) : null }

                            <div className="field-row actions">
                                <div className="row-12">
                                    {(!formValidation.isLoading)? (
                                            <button  type="submit" className="btn-login btn orange">Register</button>

                                    ) : null}

                                </div>
                            </div>
                            <div className="field-row actions">
                                <div className="row-12">
                                    <span className="requiredMessage">Fields marked with a <Required/> are required.</span>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>

                <dialog id="viewForgottenPasswordModal" className="modal">
                    <ForgottenPassword  CloseForgottenPasswordDialog={CloseForgottenPasswordDialog} />
                </dialog>


        </Page>
    )
}
export default Login;
