import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Cart from "./Cart";
import Api from "../../Api";
import Account from "./Account";
const Header = (props) => {

    const wpBaseURL = 'http://littlehouseofscience.linux2.lilo.cloud/';

    const [menuItems, setMenuItems] = useState({
        isLoading: true,
        items:[]
    });

    const [mobileMenu, setMobileMenu] = useState({
        isLoading: true,
        unsafeHtml:[]
    });
    const [showAccountMenu, setshowAccountMenu] = useState(false);

    useEffect(() => {
      //  loadMenuItems();
       // loadMobileMenu();
    },[]);

    // Load the menu items from the WordPress site through the rest API.
    function loadMenuItems() {
        // process.env.REACT_WORDPRESS_API_URL
        Api.getData(wpBaseURL + `wp-json/enrolments/v1/menu/desktop`)
            .then(data => {
                if (data !== undefined) {
                    setMenuItems({...menuItems,
                        isLoading: false,
                        items: data
                    });
                } else {
                    console.log('failed to load desktop navigation. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to desktop navigation');
                console.log(error);
            });
    }
    function isLoggedIn() {
        let userid = sessionStorage.getItem('userid');
        let token =     sessionStorage.getItem('twtoken');
        if(userid !== undefined && userid !== null && token !== undefined && token !== null) {
            return true;
        }
        return  false;
    }
    const OpenCartDialog = () => {
        let dialog = document.getElementById('viewCartModal')
        dialog.showModal();
    }
    const CloseCartDialog = () => {
        let dialog = document.getElementById('viewCartModal')
        dialog.close();
    }
    const OpenAccountDialog = () => {
        setshowAccountMenu(true);
    }
    function CloseAccountDialog(){
        setshowAccountMenu(false);
    }
    function loadMobileMenu() {
        // process.env.REACT_WORDPRESS_API_URL
        Api.getData(wpBaseURL + `wp-json/enrolments/v1/menu/mobile`)
            .then(data => {
                if (data !== undefined) {
                    setMobileMenu({...mobileMenu,
                        isLoading: false,
                        unsafeHtml: data
                    });
                } else {
                    console.log('failed to load mobile menu. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to mobile menu');
                console.log(error);
            });
    }

    return (
        <>
        <div className="template-header">
            <div className="inner-wrap">
                <div className="top-bar">
                    <div className="site-logo">
                        <a href={wpBaseURL}>
                            <img src="/assets/images/lhos-logo.png" alt="Logo - Little House of Science"/>
                        </a>
                    </div>
                    <div className="main-nav">
                        {(!menuItems.isLoading)? (
                            (menuItems.items.map(menuItem => (
                                <div key={menuItem.ID}><Link to={menuItem.url}>{menuItem.title}</Link></div>
                            )))
                        ) : null}
                        <div><Link to={"/classes"} className="btn orange">Book classes</Link></div>
                    </div>
                    <div className="user-actions">
                        {/*<img className="action" src="/assets/images/comment.svg" alt="Comment"/>*/}
                        <div className="account-icon-section">
                        {(isLoggedIn()) ?<div onClick={OpenAccountDialog}> <img className="action" src="/assets/images/user.svg" alt="Account"/></div>     :
                            <Link to='/accounts'> <img className="action" src="/assets/images/user.svg" alt="Account"/></Link>
                        }
                        {/*{(showAccountMenu) ? <Account  cartItems={props.cartItems} updateCart={props.updateCart} CloseAccountDialog={CloseAccountDialog} />:''}*/}
                        <Account  cartItems={props.cartItems} updateCart={props.updateCart} CloseAccountDialog={CloseAccountDialog} />
                        </div>
                        {(props.enableShowCart) ?
                            (<div onClick={OpenCartDialog}><img className="action" src="/assets/images/shopping-bag.svg" alt="Cart"/></div>)
                            :
                            (<div><img className="action" src="/assets/images/shopping-bag.svg" alt="Cart"/></div>)
                        }
                        {(props.enableShowCart && props.cartItems !== undefined && props.cartItems !== null && props.cartItems.length > 0) ? (<div className="cartitems alert" onClick={OpenCartDialog}>{props.cartItems.length }</div>) :'' }
                    </div>
                    {(!mobileMenu.isLoading)? (
                        <>
                            <div dangerouslySetInnerHTML={{__html: mobileMenu.unsafeHtml}}></div>
                        </>
                    ) : null}
                </div>
                <div className="bottom-bar">
                    <img src="/assets/images/header-background.png" alt="title background"/>
                    <div className="bottom-bar-inner">
                        {(props.pageTitle !== undefined && props.pageTitle !== '')? (
                            <h1 className="page-title">{props.pageTitle}</h1>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
            {(props.enableShowCart) ?
                <dialog id="viewCartModal" className="modal">
                    <div id="closeModal" className="modal-close-btn" onClick={CloseCartDialog}>
                        <img src="/assets/images/cross.svg" alt="Close" loading={"lazy"} width={20} height={20}/>
                    </div>
                    <Cart cartItems={props.cartItems} updateCart={props.updateCart} clearCart={props.clearCart} />
                </dialog>
                :'' }
        </>
    )
}
export default Header;
