import React, { useEffect, useState} from "react";
import {Link} from "react-router-dom";

const Cart = (props) => {

    const [pageData, setPageData] = useState({
        isLoading: true,
        data:[]
    });

    useEffect(() => {
        loadCart();
    }, []);
    useEffect(() => {
        if(props.cartItems !== undefined && !props.cartItems.isloading) {
            loadPage(props.cartItems);
        }
    }, [props.cartItems]);

    const removeItem = (id) => {
            props.updateCart(id);
    }
    const clearCart=() =>{
        props.clearCart();
    }

    function loadCart () {
        let cart = props.cartItems;
        return cart;
    }
    function loadPage(cart) {
        if(!props.cartItems.isloading) {
            let data = [];
            if(props.cartItems !== undefined) {
                props.cartItems.map(item => {
                        let name = (item.enrolInfullCourse) ? item.course.fullName : item.courselesson.name;
                        let amount = 0;
                        let id = item.courselesson.courselessonid;
                        if (item.enrolInfullCourse) {
                            amount = (item.course.defaultFee.amount * item.numberOfStudents)
                        } else {
                            let fee = item.courselesson.fees.filter(fee => fee.type.code === 'DEFAULTFEE');
                            if (fee !== undefined) {
                                amount = (fee[0].amount * item.numberOfStudents);
                            }
                        }

                        data.push({id: id, amount: amount, name: name});
                    }
                );
            }
            setPageData({...pageData, isLoading: false, data: data});
        }
    }
    function isLoggedIn() {
        let userid = sessionStorage.getItem('userid');
        let token =     sessionStorage.getItem('twtoken');
        if(userid !== undefined && userid !== null && token !== undefined && token !== null) {
            return true;
        }
        return  false;
    }

    return (
        <div className="cart-form-component">

            <div className="inner">
                <div className="cart-container">
                    {
                        <div className="form" >
                            <h2>Cart</h2>
                            {(!pageData.isLoading) ? (
                                <>
                                {(pageData.data.length > 0) ?(
                                    <div className="table">

                                        <div className="t-row t-heading">
                                            <div className="col c-name">
                                                Item
                                            </div>
                                            <div className="col c-amount">
                                                Amount
                                            </div>
                                            <div className="col c-actions">
                                                &nbsp;
                                            </div>
                                        </div>

                                            {(pageData.data.map(item => (
                                                <div key={item.id} className="t-row">
                                                    <div className="col c-name">
                                                        {item.name}
                                                    </div>
                                                    <div className="col c-amount">
                                                        &pound;{item.amount}
                                                    </div>
                                                    <div className="col c-actions">
                                                        <div onClick={() => removeItem(item.id)} className={"action-close"}>
                                                            <img src="/assets/images/cross.svg" alt="Close" loading={"lazy"}
                                                                 width={20} height={20}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            )))}

                                    </div>
                                    )
                                    : <div className={"cart-empty"}> No items in cart</div>}
                                </>
                            ) : ""}

                            <div className="action-container">
                                {(pageData.data.length > 0) ? (
                                    (isLoggedIn()) ? <Link to={"/checkout/details"} className="class-action btn orange" >Checkout</Link> :
                                        <Link to={"/?returnurl=checkout/details"} className="class-action btn orange" >Checkout</Link>
                                ) :  <Link  className="class-action btn orange disabled" >Checkout</Link>}
                                <div  className="class-action btn cancel" onClick={clearCart} >Clear</div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}
export default Cart;
